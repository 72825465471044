<template>
  <div>
    <!-- lg-view -->
    <v-container fluid class="ma-0 pa-0 hidden-sm-and-down">
      <v-carousel
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        height="90vh"
        :show-arrows="false"
        cycle
        interval="7000"
      >
        <v-carousel-item v-for="(slide, i) in slides" :key="i" eager>
          <v-img
            :src="require(`../../../public/slides/${slide}`)"
            min-width="100%"
            max-width="100%"
            max-height="110vh"
            eager
            class="mr-auto"
            gradient="to top left, rgba(0,0,0,.5), rgba(0,0,0,.5)"
          >
          </v-img>
        </v-carousel-item>
      </v-carousel>
      <v-layout
        row
        wrap
        align-center
        justify-center
        relative
        style="height: 90vh"
      >
        <v-flex justify-center text-center style="z-index: 1">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-items: center;
              align-items: center;
            "
          >
            <div>
              <p class="white--text">Welcome to</p>

              <h1
                class="heroText nephilim white--text"
                style="font-size: 5vw !important"
              >
                Petty Medical Clinic
              </h1>
              <div class="w-25 border border-2 mx-auto" style="width: 50%">
                <p class="heroSubtext white--text">
                  Our dedicated specialists provide expert care and personalized
                  attention to help you take control of your health. Explore our
                  range of in-house services and treatments and let us help you
                  become the best version of yourself.
                </p>
              </div>
              <v-btn
                elevation="4"
                width="85px"
                class="space-around align-self-center px-4 mt-4 text--white px-14"
                color="rgba(255,255,255,.2)"
                style="
                  color: white !important;
                  border: 1px solid white;
                  border-radius: none !important;
                "
                href="/contact"
              >
                Visit Us
              </v-btn>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- mobile view -->
    <v-container
      class="pa-0 hidden-md-and-up"
      style="width: 100%; height: 75vh"
    >
      <v-carousel
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        height="75vh"
        :show-arrows="false"
        cycle
        interval="7000"
      >
        <v-carousel-item v-for="(slide, i) in slides" :key="i" eager>
          <v-img
            :src="require(`../../../public/slides/${slide}`)"
            eager
            cover
            min-height="100%"
            class="mr-auto"
            gradient="to top left, rgba(0,0,0,.5), rgba(0,0,0,.5)"
          >
          </v-img>
        </v-carousel-item>
      </v-carousel>
      <v-container
        class="d-flex flex-column justify-center align-center"
        style="max-height: 75vh; height: 100%; z-index: 1"
      >
        <h4
          class="white--text mt-2 text-center"
          style="font-weight: 300; z-index: inherit"
        >
          Welcome to
        </h4>

        <h2
          class="heroText white--text mt-3 nephilm text-center"
          style="font-size: 9vw; z-index: inherit"
        >
          Petty Medical Clinic
        </h2>
        <p
          class="heroSubtextMobile white--text mt-2 text-center"
          style="z-index: inherit"
        >
          Focused on expert care and making your health the highest priority,
          the specialists at Petty Medical Clinic work with you to take control
          of your health.
        </p>

        <v-btn
          elevation="4"
          width="85px"
          class="space-around align-self-center text--white my-4 px-12"
          color="rgba(255,255,255,.2)"
          style="
            color: white !important;
            border: 1px solid white;
            border-radius: none !important;
          "
          href="/contact"
        >
          Visit Us
        </v-btn>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {},
  data: () => ({
    slides: require
      .context("../../../public/slides", false)
      .keys()
      .map((key) => {
        return key.replace("./", "");
      }),
  }),
};
</script>

<style scoped>
@import "../../scss/variables.scss";

.hoursText {
  color: white;
  opacity: 0.5;
}

.heroSubtext {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
}

.heroSubtextMobile {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

.v-carousel {
  position: absolute !important;
}
</style>
