<template>
  <div>
    <v-dialog
      v-model="dialog"
      :max-width="$vuetify.breakpoint.mobile ? '95vw' : '75vw'"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Privacy Policy</span>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="modal-content">
          Last updated: 4/6/2023 Petty Medical operates the PettyMedical.com
          website. <br /><br />
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal information when you use our Service.
          <br /><br />
          We do not collect any personally identifiable information from you
          when you use our Service. As such, this privacy policy is quite simple
          and straightforward. However, we still take your privacy seriously and
          want to be transparent about our practices.
          <br /><br />1. Information Collection and Use We do not collect any
          personally identifiable information from you when you visit or
          interact with our website. This includes information such as your
          name, email address, phone number, or other personal details.
          <br /><br />2. Log Data We may collect information that your browser
          sends whenever you visit our Service ("Log Data"). This Log Data may
          include information such as your computer's Internet Protocol ("IP")
          address, browser type, browser version, the pages of our Service that
          you visit, the time and date of your visit, the time spent on those
          pages and other statistics. The purpose of collecting this information
          is to better understand how our visitors use the Service, and to
          maintain and improve the quality of our Service. However, this data
          does not personally identify you. <br /><br />3. Cookies Cookies are
          files with a small amount of data, which may include an anonymous
          unique identifier. Cookies are sent to your browser from a website and
          stored on your computer's hard drive. We do not use cookies to collect
          personally identifiable information, but we may use them to improve
          the overall user experience of our website, such as remembering your
          preferences or enhancing the site's performance. <br /><br />4.
          Service Providers We may employ third-party companies and individuals
          to facilitate our Service, to provide the Service on our behalf, or to
          assist us in analyzing how our Service is used. These third parties
          have access to your non-personally identifiable information only to
          perform these tasks on our behalf and are obligated not to disclose or
          use it for any other purpose. <br /><br />5. Security The security of
          your personal information is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage, is 100% secure. While we strive to protect your personal
          information, we cannot guarantee its absolute security. <br /><br />6.
          Changes to This Privacy Policy We may update our Privacy Policy from
          time to time. We will notify you of any changes by posting the new
          Privacy Policy on this page. You are advised to review this Privacy
          Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page. <br /><br />7.
          Contact Us If you have any questions about this Privacy Policy, please
          contact us: • By email: pettymedical@clinic.com • By phone:
          479-254-1005
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PopupModal",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
  mounted() {
    this.$parent.$on("open-modal", () => {
      this.dialog = true;
    });
  },
};
</script>

<style scoped>
.modal-content {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
