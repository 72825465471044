export default {
  methods: {
    capFirstLetter: function (word) {
      return word.toUpperCase();
    },
    capFirstLetterOfEachWord: function (sentence) {
      return sentence
        .split(" ")
        .map((word) => this.capFirstLetter(word))
        .join(" ");
    },
  },

  services: [
    {
      name: "NEO EMSCULPT",
      description:
        "Neo EMsculpt is a revolutionary non-invasive body contouring treatment that utilizes high-intensity electromagnetic \
      energy to build muscle and reduce fat. This FDA-cleared technology can help you achieve a more sculpted, toned physique by \
      strengthening muscle fibers and eliminating unwanted fat in just a few short sessions. With no downtime and long-lasting results, \
      Neo EMsculpt is the perfect solution for anyone looking to enhance their physical appearance and feel their best.",
      path: "NeoEmsculpt.webp",
      imgColWith: 3,
      shortDescription:
        "Neo EMsculpt is a non-invasive body contouring treatment that builds muscle and reduces fat using high-intensity \
      electromagnetic energy. With no downtime and long-lasting results, it's the perfect solution for a toned and sculpted physique.",
    },
    {
      name: "ALMA TED HAIR RESTORATION",
      description:
        "Discover the power of Alma TED, a revolutionary non-invasive hair loss prevention treatment designed to restore \
        your hair without the need for needles or surgery. Utilizing state-of-the-art ultrasound technology and a proprietary serum, Alma TED \
        stimulates the scalp, promoting hair growth and preventing future hair loss. With comfortable sessions and zero downtime, this \
        innovative treatment helps you achieve thicker, healthier hair with ease. Experience the difference that Alma TED can make for your hair today!",
      path: "Alma_ted.webp",
      imgColWith: 4,
      shortDescription:
        "Restore your hair with Alma TED, a non-invasive hair loss prevention treatment using ultrasound technology. Achieve thicker, healthier hair with zero downtime.",
    },
    {
      name: "ANTI-AGING THERAPY",
      description:
        "Experience the power of anti-aging therapy and turn back the clock on aging! \
      Our specialized anti-aging treatment is designed to reduce wrinkles, fine lines, and dark spots and give you smooth,\
      youthful skin. Our therapy combines the latest technology and natural ingredients to restore a healthy glow and leave you\
      feeling confident and beautiful! Try the ultimate in anti-aging today!",
      path: "Skin_Peel.webp",
      imgColWith: 4,
      shortDescription:
        "Turn back the clock on aging with our specialized anti-aging therapy. Our treatment reduces wrinkles, \
      fine lines, and dark spots for smooth, youthful-looking skin. Try the ultimate in anti-aging today!",
    },
    {
      name: "HORMONE THERAPY",
      description:
        "Dr. Petty's office offers hormone therapy to help improve your overall wellness. Our therapy can \
      help boost your energy levels, restore your libido, reduce fatigue and irritability, and even improve your metabolism. \
      With personalized care and treatments tailored to your individual needs, you can experience the long-term benefits of \
      hormone therapy and enjoy a healthier, happier lifestyle.",
      path: "Hormone_Therapy.webp",
      imgColWith: 4,
      shortDescription:
        "Boost your overall wellness with our personalized hormone therapy treatments. Restore energy, libido, and metabolism for a happier, healthier lifestyle.",
    },
    {
      name: "PEPTIDE THERAPY",
      description:
        "At our facility, we offer a solution to treat injuries, prevent chronic disease, and enhance peak \
      performance using our revolutionary peptide therapy. Our peptide therapy is designed to regulate and rejuvenate \
      neuro-endocrine-immune functionality, giving you a safe and effective solution to improve your overall health and wellbeing. \
      Experience the power of peptide therapy today and enjoy the benefits for a lifetime!",
      path: "DrPatient2.webp",
      imgColWith: 4,
      shortDescription:
        "Revolutionize your health with peptide therapy at Petty Medical. Enhance performance, prevent chronic disease, \
      and improve overall well-being with our safe and effective treatment.",
    },
    {
      name: "IV THERAPY",
      description:
        "IV therapies are a powerful tool to help you achieve optimal health, energy and wellbeing. \
      Whether you are feeling run down, need a pick me up, or have specific health goals, we have the perfect IV \
      cocktail for you. Our custom cocktails use a range of ingredients, from Vitamin C and Immune Boost cocktails to\
      Phosphatidylcholine and NAD+, to help you reach your health goals faster and more efficiently. We believe in \
      the power of IV therapies and can help you find the perfect cocktail for your needs.",
      path: "IV_Therapy.webp",
      imgColWith: 4,
      shortDescription:
        "IV therapies at Petty Medical help you achieve optimal health and energy with custom cocktails \
      tailored to your needs. From Vitamin C to Phosphatidylcholine, our range of ingredients can help you reach your health \
      goals faster and more efficiently.",
    },
    {
      name: "REGENERATIVE THERAPY",
      description:
        "Regenerative therapy offers a unique alternative to traditional treatments. By utilizing the \
      body's natural healing process, it helps to rebuild damaged tissue, heal injuries, and reduce pain and inflammation, \
      leaving you feeling healthier and stronger than ever before. With proven results and no side effects, regenerative \
      therapy is an ideal choice for those seeking an effective, non-invasive method of healing.",
      path: "DrPatient1.webp",
      imgColWith: 4,
      shortDescription:
        "Experience natural healing with regenerative therapy. Rebuild damaged tissue and reduce pain and \
      inflammation without side effects. Choose non-invasive healing today!",
    },
    {
      name: "SEXUAL HEALTH THERAPY",
      description:
        "Sexual health is an important part of overall health and wellbeing. At our facility, we offer a \
      range of sexual health therapies to help you achieve optimal sexual health and performance. Whether you are looking \
      to improve your sexual performance, pleasure, or enhance personal relationships, we have the perfect therapy for you.",
      path: "Sexual_Health.webp",
      imgColWith: 4,
      shortDescription:
        "At Petty Medical, we offer sexual health therapies to enhance overall well-being. Improve performance, \
      pleasure, and personal relationships with our range of therapies designed for optimal sexual health.",
    },
    {
      name: "COSMETIC INJECTABLES",
      description:
        "At Petty Medical, we offer a range of cosmetic injectables - including dermal fillers like Juvederm and \
      Restylane, and neuromodulators like Botox - to help our clients achieve a more youthful, refreshed appearance. These \
      minimally invasive treatments provide immediate results with little to no downtime, making them a convenient option for \
      those looking to enhance their natural beauty. Our skilled specialists customize each treatment to your unique needs, ensuring \
      natural-looking results that boost your confidence and leave you feeling your best.",
      path: "Cosmetics.webp",
      imgColWith: 4,
      shortDescription:
        "Enhance your natural beauty with Petty Medical's cosmetic injectables. From Juvederm to Botox, our \
      treatments provide immediate, natural-looking results with little to no downtime. Let our specialists customize your treatment \
      for a confidence boost and refreshed appearance.",
    },
    {
      name: "SKIN REJUVENATION",
      description:
        "At Petty Medical, we offer microneedling and chemical peels - two highly effective options that can help you achieve a \
      smoother, more youthful-looking complexion. Microneedling involves using tiny needles to create micro-injuries in the skin,\
       stimulating collagen production and improving overall skin texture and appearance. Chemical peels, on the other hand, use a \
       chemical solution to exfoliate the top layer of the skin, revealing smoother, more radiant skin underneath.",
      path: "Skin_Peel.webp",
      imgColWith: 4,
      shortDescription:
        "Get smoother, more youthful-looking skin with Petty Medical's microneedling and chemical peels. Our highly \
      effective options stimulate collagen production and exfoliate for a radiant complexion. Experience refreshed skin today!",
    },
    {
      name: "DIETARY SUPPLEMENTS",
      description:
        "At Petty Medical, we offer a wide variety of high-quality dietary supplements and vitamins that Dr. Petty recommends \
      based on each patient's individual needs. Our supplements are made from high-quality ingredients and are designed to support overall \
      health and wellness. Whether you're looking to boost your immune system, improve digestion, or support healthy aging, we have the right \
      supplements to help you achieve your goals. Our team is dedicated to providing personalized attention and customized care to help \
      you achieve optimal health and well-being.",
      path: "Dietery_Supplements.webp",
      imgColWith: 4,
      shortDescription:
        "Petty Medical offers high-quality dietary supplements and vitamins tailored to your individual needs. \
      Boost your immune system, improve digestion, and support healthy aging with our personalized care. Achieve optimal health and \
      well-being today!",
    },
    {
      name: "EMSELLA",
      description:
        "The EMSELLA chair is a non-invasive, FDA-cleared treatment that uses high-intensity electromagnetic energy to \
      strengthen pelvic muscles and improve urinary incontinence. This innovative technology can help you regain control of your bladder \
      and improve your quality of life without the need for surgery or medication. With comfortable, easy sessions and no downtime, \
      EMSELLA is a convenient and effective solution for anyone looking to improve their pelvic health.",
      path: "emsella.webp",
      imgColWith: 4,
      shortDescription:
        "The EMSELLA chair is a non-invasive treatment that uses high-intensity electromagnetic energy to \
      strengthen pelvic muscles and improve urinary incontinence. Improve your pelvic health without surgery or medication, \
      with comfortable, easy sessions and no downtime.",
    },
  ],
  privacyPolicy: `Last updated: 4/6/2023
  Petty Medical operates the PettyMedical.com website. \n
  This page informs you of our policies regarding the collection, use, and disclosure of personal information when you use our Service.
  We do not collect any personally identifiable information from you when you use our Service. As such, this privacy policy is quite simple and straightforward. However, we still take your privacy seriously and want to be transparent about our practices.
  1.	Information Collection and Use
  We do not collect any personally identifiable information from you when you visit or interact with our website. This includes information such as your name, email address, phone number, or other personal details.
  2.	Log Data
  We may collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.
  The purpose of collecting this information is to better understand how our visitors use the Service, and to maintain and improve the quality of our Service. However, this data does not personally identify you.
  3.	Cookies
  Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive.
  We do not use cookies to collect personally identifiable information, but we may use them to improve the overall user experience of our website, such as remembering your preferences or enhancing the site's performance.
  4.	Service Providers
  We may employ third-party companies and individuals to facilitate our Service, to provide the Service on our behalf, or to assist us in analyzing how our Service is used. These third parties have access to your non-personally identifiable information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
  5.	Security
  The security of your personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
  6.	Changes to This Privacy Policy
  We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
  You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
  7.	Contact Us
  If you have any questions about this Privacy Policy, please contact us:
  •	By email: pettymedical@clinic.com
  •	By phone: 479-254-1005
  `,
};
