<template>
  <div>
    <div class="hidden-sm-and-down desktop-footer">
      <v-container fluid class="footer-content">
        <v-row class="footer-row" fluid>
          <v-col
            cols="4"
            class="white--text text-h4 footer-col text-center d-flex"
            >Transforming lives with personalized care</v-col
          >
          <v-col cols="4" class="white--text text-h4 contact-col">
            <p class="white--text text-subtitle-1 text-center">Contact us</p>
            <div class="d-flex flex-column justify-center align-center">
              <a href="tel:+4792541005" class="contact-link"
                >Phone 479.254.1005</a
              >
              <a href="mailto:pettymedical@clinic.com" class="contact-link"
                >pettymedical@clinic.com</a
              >
              <div class="text-center">
                <p class="text-xs mt-4">3201 Market Street, Suite 105,</p>
                <p class="text-xs">Suite 105, Rogers, Arkansas 72758,</p>
                <p class="text-xs">United States</p>
              </div>
            </div>
            <p class="white--text text-subtitle-1 mt-16 text-center">
              Follow us
            </p>
            <div class="d-flex flex-column justify-center align-center">
              <a v-bind:href="instagram" class="contact-link">Instagram</a>
              <a v-bind:href="facebook" class="contact-link">Facebook</a>
            </div>
          </v-col>
          <v-col
            cols="4"
            class="white--text text-h4 menu-col flex d-flex flex-column align-center"
          >
            <p class="white--text text-subtitle-1 text-center">Menu</p>
            <router-link text color="white" to="/" class="text-underline"
              >HOME</router-link
            >
            <router-link
              text
              color="white"
              to="/services"
              class="text-underline"
              >SERVICES</router-link
            >
            <router-link text color="white" to="/about" class="text-underline"
              >ABOUT US</router-link
            >
            <router-link text color="white" to="/contact" class="text-underline"
              >CONTACT US</router-link
            >
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-footer
      class="desktop-footer-bottom hidden-sm-and-down"
      height="75px"
      style="background-color: var(--secondary-light)"
    >
      <v-row style="background-color: var(--secondary-light)">
        <v-col cols="4" align="center" class="white--text heroText nephilm"
          >Petty Medical Clinic</v-col
        >
        <v-col cols="4" align="center" class="white--text pl-10"
          >{{ new Date().getFullYear() }} - All Rights Reserved</v-col
        >
        <v-col cols="4" align="center" class="privacy-policy-col">
          <p class="white--text mr-16 pr-2" @click="openModal()">
            Privacy Policy
          </p>
        </v-col>
      </v-row>
    </v-footer>

    <!-- mobile view -->
    <div class="hidden-md-and-up mobile-footer">
      <v-container class="footer-container ml-10">
        <p class="heroText white--text nephilm text-h4 mb-8 mt-4">
          Petty Medical Clinic
        </p>
        <v-row class="contact-row">
          <div class="v-col">
            <p class="white--text">Contact us</p>
            <a href="tel:+4792541005" class="contact-link">
              <p class="mt-3">Phone 479.254.1005</p>
            </a>
          </div>
        </v-row>
        <v-row class="email-row mt-5">
          <div class="v-col">
            <a href="mailto:pettymedical@clinic.com" class="email-link">
              <p>Pettymedical@clinic.com</p>
            </a>
          </div>
        </v-row>
        <v-row class="menu-row mt-8">
          <v-col
            cols="12"
            class="white--text text-h4 mr-4 flex d-flex flex-column"
          >
            <p class="white--text">Menu</p>
            <router-link text color="white" to="/" class="text-underline"
              >HOME</router-link
            >
            <router-link
              text
              color="white"
              to="/services"
              class="text-underline"
              >SERVICES</router-link
            >
            <router-link text color="white" to="/about" class="text-underline"
              >ABOUT US</router-link
            >
            <router-link text color="white" to="/contact" class="text-underline"
              >CONTACT US</router-link
            >
          </v-col>
        </v-row>
        <v-row class="flex-column pt-4 ms-1">
          <p class="white--text">Follow us</p>
          <a v-bind:href="instagram" class="contact-link">Instagram</a>
          <a v-bind:href="facebook" class="contact-link">Facebook</a>
        </v-row>
        <div class="copyright-row mt-10 pr-4">
          <div align="start" class="white--text">
            <p class="text-body-2">
              {{ new Date().getFullYear() }} - All Rights Reserved
            </p>
          </div>
          <div class="privacy-policy-col pt-4 text-underline white--text">
            <p class="white--text text-body-2" @click="openModal()">
              Privacy Policy
            </p>
          </div>
        </div>
      </v-container>
    </div>
    <PrivacyPolicyModal />
  </div>
</template>

<script>
import PrivacyPolicyModal from "./PrivacyPolicyModal.vue";

export default {
  name: "Footer",
  components: {
    PrivacyPolicyModal,
  },
  methods: {
    openModal() {
      this.$emit("open-modal");
    },
  },
  data() {
    return {
      instagram: "https://www.instagram.com/petty_medical_clinic/",
      facebook: "https://www.facebook.com/PettyMedicalClinic/",
    };
  },
};
</script>

<style scoped>
.nephilm {
  font-family: "Nephilim" !important;
}

.desktop-footer {
  background: var(--secondary);
  height: fit-content;
  width: 100%;
}

.footer-content {
  margin: 0 16px;
  /* margin-bottom: 0; */
  background-color: var(--secondary);
}

.footer-card {
  color: transparent;
  margin: 16px;
  background-color: var(--secondary);
}

.footer-row {
  margin: 16px auto;
  width: 90vw !important;
  background-color: var(--secondary);
}

.footer-col,
.contact-col,
.menu-col {
  margin-bottom: 4px;
}

.contact-link,
.email-link {
  text-decoration: underline;
  font-size: 1.5rem;
  color: white;
}

.desktop-footer-bottom {
  color: var(--secondary-light);
}

.desktop-footer-bottom-card {
  color: var(--secondary-light);
  background-color: var(--secondary);
}

.privacy-policy-col {
  cursor: pointer;
}

.mobile-footer {
  background: var(--secondary);
  width: 100vw;
}

.footer-container {
  width: 100vw;
}

.contact-row,
.email-row {
  margin-top: 4px;
  margin-left: 1px;
}

.contact-link p,
.email-link p {
  font-size: 1.25rem;
  color: white;
}

.v-footer {
  background-color: var(--secondary);
}
.text-xs {
  font-size: 1rem;
}
.text-underline {
  text-decoration: underline;
}
</style>
