<template>
  <!-- lg view -->
  <div>
    <v-container fluid class="ma-0 pa-0 hidden-sm-and-down">
      <v-toolbar
        absolute
        shrink-on-scroll
        prominent
        dense
        color="transparent"
        elevation="0"
        max-width="100vw"
        max-height="85px"
        class="z-index-5"
      >
        <v-row class-="w-75">
          <v-toolbar-title class="ml-8 heroText nephilim">
            Petty Medical Clinic
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-card absolute right class="mt-7" color="transparent">
            <v-tabs
              background-color="transparent"
              elevation="0"
              style="color: white"
              v-model="drawerModel"
            >
              <v-tab
                v-for="route in $router.options.routes"
                :key="route.path"
                class="mx-2"
                :value="route.name"
              >
                <router-link :to="route.path" class="navLink">{{
                  capFirstLetter(route.name)
                }}</router-link>
              </v-tab>
            </v-tabs>
          </v-card>
        </v-row>
      </v-toolbar>
    </v-container>

    <!-- mobile view -->
    <div>
      <v-container fluid class="ma-0 pa-0 hidden-md-and-up">
        <v-app-bar color="transparent">
          <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <p class="ml-8 heroText text--white">Petty Medical Clinic</p>
        </v-app-bar>
        <v-navigation-drawer
          v-model="drawer"
          app
          absolute
          temporary
          class="custom-drawer"
          style="z-index: 9 !important"
        >
          <v-list nav dense>
            <v-list-item @click="toggleDrawer">
              <v-spacer></v-spacer>
              <v-list-item-icon>
                <v-icon class="text--white" color="white" size="28"
                  >mdi-close</v-icon
                >
              </v-list-item-icon>
            </v-list-item>
            <v-list-item-group active-class=" text--accent-4">
              <v-list-item></v-list-item>
              <v-list-item
                v-for="route in $router.options.routes"
                :key="route.path"
                class="mt-6 text-center"
                :value="route.name"
              >
                <v-list-item-title>
                  <router-link
                    :to="route.path"
                    class="text--white nav-link-text"
                    @click="toggleDrawer"
                  >
                    {{ capFirstLetter(route.name) }}
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-container>
    </div>
  </div>
</template>

<script>
import HelperFunctions from "../mixins/HelperFunctions.js";
export default {
  name: "Navigation",
  data: () => ({
    drawer: false,
    drawerModel: null,
  }),
  mixins: [HelperFunctions],
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.drawerModel = to.name;
    next();
  },
};
</script>

<style scoped>
.router-link-exact-active {
  color: white !important;
}

.theme--light.v-list {
  color: rgb(0, 89, 255) !important;
}

.nav-link-text {
  font-size: 32px;
  line-height: 1.2;
}

.custom-drawer {
  height: 100vh;
  width: 100vw !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.z-index-5 {
  z-index: 5 !important;
}
</style>
