<template>
  <v-container class="d-flex justify-center">
    <!-- Desktop Image -->
    <v-card class="px-4 hidden-sm-and-down pa-4" style="max-width: 300px">
      <v-col>
        <v-img
          :src="require(`../../../public/service-slides/${imgSrc}`)"
          width="300"
          aspect-ratio="1"
          cover
        ></v-img>

        <hr class="my-4" style="border-top: 1px solid var(--secondary)" />
        <h3 class="montserrat">{{ title }}</h3>
        <p
          class="pt-4"
          style="color: var(--secondary); font-size: 14px; max-width: 300px"
        >
          {{ content }}
        </p>
      </v-col>
    </v-card>

    <!-- Mobile -->
    <v-col class="hidden-md-and-up align-center px-4" style="max-width: 400px">
      <v-img
        :src="require(`../../../public/service-slides/${imgSrc}`)"
        width="100%"
        aspect-ratio="1"
        cover
      ></v-img>
      <hr class="my-4" style="border-top: 1px solid var(--secondary)" />
      <h3 class="montserrat wrap">{{ title }}</h3>
      <p class="pt-4 wrap" style="color: var(--secondary); font-size: 14px">
        {{ content }}
      </p>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "HomeServiceInfoCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      required: true,
    },
  },

  data: () => ({}),
};
</script>
