<template>
  <div>
    <HomeHeader />
    <HomeWhyUs />
    <HomeServices />
    <HomeMoreInfo />
    <!-- <HomeNewsletterSect /> -->
  </div>
</template>

<script>
import HomeHeader from "../components/Homepage/HomeHeader.vue";
import HomeWhyUs from "@/components/Homepage/HomeWhyUs.vue";
import HomeServices from "@/components/Homepage/HomeServices.vue";
import HomeMoreInfo from "@/components/Homepage/HomeMoreInfo.vue";
// import HomeNewsletterSect from "@/components/Homepage/HomeNewsletterSect.vue";

export default {
  name: "HomeView",

  components: {
    HomeHeader,
    HomeWhyUs,
    HomeServices,
    HomeMoreInfo,
    // HomeNewsletterSect,
  },
};
</script>
<style scoped></style>
