<template>
  <v-app>
    <Navigation />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer/Footer.vue";

import { register } from "swiper/element/bundle";
register();

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },
  data: () => ({
    //
  }),
};
</script>
<style>
* {
  /* declare variable */
  --secondary: rgb(138, 153, 180);
  --secondary-light: rgb(186, 197, 218);
  --secondary-light2: rgb(155, 173, 201);
  --lightText: rgba(255, 255, 255, 0.15) !important;
  font-size: 20px;

  --swiper-pagination-color: var(--secondary);
  --swiper-pagination-bullet-size: 32px;
  --swiper-pagination-bullet-inactive-color: var(--secondary);
  --swiper-pagination-bullet-inactive-opacity: 0.25;
  --swiper-pagination-bullet-horizontal-gap: 4px;

  @font-face {
    font-family: "Nephilm";
    src: url("~/public/fonts/Nephilm.otf"format("opentype"));
    font-weight: 100 200;
  }
}

body {
  overflow: hidden;
  font-family: "Montserrat", sans-serif !important;
}

.nephilm {
  font-family: "Nephilm";
  font-weight: 200 !important;
}

.montserrat {
  font-family: "Montserrat";
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.row {
  width: 100vw;
}

.v-toolbar__title,
.scriptTitle {
  font-size: 24px !important;
  font-weight: 500;
  color: white;
}

.v-application p {
  margin-bottom: 0;
}

/* text styles */
.lgText {
  font-size: 17rem;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3rem !important;
  /* margin-bottom: 1rem; */
}

.heroText {
  font-family: "Nephilim", serif !important;
  font-weight: 400;
  /* font-family: 'PT Serif', serif; */
}
</style>
