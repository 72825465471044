<template>
  <div style="height: 100%">
    <!-- Lg view -->
    <v-container
      fluid
      class="ma-0 pa-0 mb-0 hidden-sm-and-down pt-10"
      style="background-color: var(--secondary); position: relative"
    >
      <p
        style="color: var(--lightText); font-weight: 300; line-height: 150px"
        class="lgText sm-ml-2 ml-16 pt-2 pt-16"
      >
        WHY US?
      </p>

      <v-row align="start" no-gutters height="" class="pb-12">
        <v-col cols="8">
          <v-card class="ml-10" color="transparent">
            <h3
              fixed
              class="white--text h3-lg nephilm"
              style="line-height: 50px"
            >
              A COMPREHENSIVE
            </h3>
            <h3 class="white--text h3-lg nephilm" style="">APPROACH</h3>
          </v-card>
          <v-card class="pa-2 ml-10" color="transparent">
            <p
              fixed
              class="white--text montserrat"
              style="width: 50%; font-weight: 300"
            >
              At Petty Medical, we believe that healthcare should be about more
              than just treating existing conditions. That's why Dr. Petty is
              committed to maximizing prevention strategies and helping you
              achieve your wellness goals.
            </p>
          </v-card>
          <v-img
            src="../../../public/slides/Slide-1-2.webp"
            width="85%"
            class="mt-10 ml-10"
          ></v-img>
          <div
            class="text-center justify-center align-center d-flex flex-column mr-16"
          >
            <h3
              fixed
              class="white--text h3-lg nephilm"
              style="margin-top: 4rem !important"
            >
              A PHYSICIAN THAT TRULY CARES
            </h3>
            <p
              fixed
              class="white--text montserrat text-center justify-center"
              style="width: 50%; font-weight: 300"
            >
              Not only will Dr. Petty treat your existing conditions, he will
              work to maximize prevention stratagies. At Petty Medical Clinic,
              we strive to help you improve your quality of life, achieve your
              wellness goals, and support your best possible life.
            </p>
          </div>
        </v-col>
        <v-col class="" style="line-height: 207px" cols="4">
          <h3
            class="white--text h3-lg montserrat"
            absolute
            style="font-size: 10rem; top: 0"
          >
            12
          </h3>
          <p class="servicesText mb-4" style="line-height: 22px">Services</p>
          <h3
            class="white--text h3-lg montserrat"
            absolute
            style="font-size: 10rem; top: 0"
          >
            5
          </h3>
          <p class="servicesText" style="line-height: 22px">Days a Week</p>
          <v-spacer></v-spacer>
          <div class="mt-16">
            <h3
              fixed
              class="white--text ml-4 h3-md nephilm"
              style="line-height: 50px"
            >
              PATIENT
            </h3>
            <h3
              class="white--text ml-4 h3-md nephilm"
              style="line-height: 2rem"
            >
              CENTERED CARE
            </h3>
            <p
              class="white--text montserrat"
              style="line-height: 1.75em; width: 75%; margin-top: 1em"
            >
              At Petty Medical, we believe that every patient is unique and
              deserves individualized care. Dr. Petty is committed to
              understanding your preferences, needs, and values to provide the
              best possible treatment. He thinks outside the box and utilizes
              his expertise to deliver innovative solutions that improve patient
              outcomes. With a focus on personalized attention and customized
              care, we're dedicated to helping you achieve optimal health and
              well-being.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- tablet and mobile view -->
    <v-container
      class="hidden-md-and-up d-flex-column ma-0 pa-0"
      fluid
      style="height: min-content; background-color: var(--secondary)"
    >
      <p
        style="
          color: var(--secondary-light2);
          font-weight: 300;
          font-size: 5rem;
          width: 100%;
          overflow-wrap: break-word !important;
          line-height: 7rem;
          text-align: center;
          z-index: 0 !important;
        "
      >
        WHY US?
      </p>
      <p
        fixed
        class="white--text nephilm px-4"
        style="
          font-size: 8vw;
          margin: inherit auto !important;
          line-height: 3.2rem;
        "
      >
        A COMPREHENSIVE APPROACH
      </p>
      <p fixed class="white--text px-4 mt-4" style="font-weight: 300">
        At Petty Medical, we believe that healthcare should be about more than
        just treating existing conditions. That's why Dr. Petty is committed to
        maximizing prevention strategies and helping you achieve your wellness
        goals.
      </p>

      <v-card color="transparent" class="px-4 mt-8">
        <h3
          class="white--text h3-md montserrat"
          style="font-size: 5rem; line-height: 4rem; letter-spacing: 5px"
        >
          12 <span class="servicesText">Services</span>
        </h3>

        <h3
          class="white--text h3-md mb-16 montserrat"
          absolute
          style="
            font-size: 5rem;
            top: 0;
            line-height: 4rem;
            letter-spacing: 5px;
          "
        >
          5 <span class="servicesText montserrat">Days a week</span>
        </h3>
      </v-card>

      <v-img
        src="../../../public/slides/Slide-1-2.webp"
        width="500px"
        class="ma-0 pa-0 mt-8"
        min-width="100vw"
      ></v-img>

      <p
        fixed
        class="white--text nephilm px-4 mt-8"
        style="
          font-size: 8vw;
          margin: inherit auto !important;
          line-height: 3.2rem;
        "
      >
        PATIENT CENTERED CARE
      </p>
      <p fixed class="white--text px-4 mt-4" style="font-weight: 300">
        At Petty Medical, we believe that every patient is unique and deserves
        individualized care. Dr. Petty is committed to understanding your
        preferences, needs, and values to provide the best possible treatment.
        He thinks outside the box and utilizes his expertise to deliver
        innovative solutions that improve patient outcomes. With a focus on
        personalized attention and customized care, we're dedicated to helping
        you achieve optimal health and well-being.
      </p>

      <p
        fixed
        class="white--text nephilm px-4 mt-8"
        style="
          font-size: 8vw;
          margin: inherit auto !important;
          line-height: 3.2rem;
          text-align: right;
        "
      >
        A PHYSICIAN THAT TRULY CARES
      </p>
      <p
        fixed
        class="white--text mt-4 pr-4 pb-10"
        style="font-weight: 300; text-align: right"
      >
        Not only will Dr. Petty treat your existing conditions, he will work to
        maximize prevention strategies. At Petty Medical Clinic, we strive to
        help you improve your quality of life, achieve your wellness goals, and
        support your best possible life.
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style scoped>
.overlapHeaderSubtext {
  position: absolute;
  top: 17rem;
  left: 6rem;
}

.h3-lg {
  font-size: 48px;
  font-weight: 300;
  margin: 0 !important;
  padding: 0 !important;
}

.h3-md {
  font-size: 30px;
  font-weight: 400;
  margin: 0 !important;
  padding: 0 !important;
}

.servicesText {
  color: white;
  line-height: 100px;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.v-card {
  width: 100% !important;
}
</style>
